import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Logo from "../assets/logo.png";

import English from "../assets/English.svg"
import Français from "../assets/Français.svg"

export function Navbar() {
  const { t, i18n } = useTranslation();

  const lngs = {
    en: { nativeName: "English", img: English },
    fr: { nativeName: "French", img: Français },
  };

  const location = useLocation();

  return (
    <>
      <nav>
        <div className="brand">
          <img src={Logo} alt="logo" />
        </div>
        <div className="nav-content">
        <ul>
          <li className={location.pathname === "/" ? "active" : ""}>
            <Link to="/">Home</Link>
          </li>
          <li className={location.pathname === "/mint" ? "active" : ""}>
            <Link to="/mint">Mint</Link>
          </li>
          <li className={location.pathname === "/referral" ? "active" : ""}>
            <Link to="/referral">Referral</Link>
          </li>
          <li className={location.pathname === "/referral" ? "active" : ""}>
            <Link to="/dashboard">App</Link>
          </li>
        </ul>
        <div>{Object.keys(lngs).map((lng) => (
            <button
              key={lng}
              style={{
                background: "none",
                border: "none",
                transition: ".4s",
                filter: lng === i18n.language ? "grayscale(0)" : "grayscale(100)",
                cursor: "pointer"
              }}
              type="submit"
              onClick={() => i18n.changeLanguage(lng)}
            >
                <img src={lng !== "en" ? Français : English} alt={lng} style={{
                    width: "40px"
                }} />
            </button>
          ))}</div>
        </div>
      </nav>
    </>
  );
}
