import firebase from 'firebase/compat/app'
import "firebase/compat/firestore"
import "firebase/compat/auth"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAi_e3mbNYCztHZ6NQMFOOcz70G5cXUc18",
  authDomain: "waren-2.firebaseapp.com",
  projectId: "waren-2",
  storageBucket: "waren-2.appspot.com",
  messagingSenderId: "1003648679371",
  appId: "1:1003648679371:web:b12f7e6a83fa4b28f90a4e",
  measurementId: "G-MNP8G9F6BC"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;