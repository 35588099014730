import React, { useRef } from "react";
import { Link } from "react-router-dom";

import { Navbar } from "../components/Navbar";

import Screen1 from "../assets/phone1.png";
import Screen2 from "../assets/phone2.png";
import Screen3 from "../assets/phone3.png";
import Down from "../assets/chevron-down-solid.svg";
import AppleStore from "../assets/appStore.png";
import PlayStore from "../assets/googlePlay.png";
import Flex1 from "../assets/flex1.png";
import Flex2 from "../assets/flex2.png";
import Flex3 from "../assets/flex3.png";
import Jet from "../assets/jet-2.png";
import Roadmap from "../assets/roadmap.jpg"

import "../styles/css/style.css";
import { Accordion } from "../components/Accordion";

import { useTranslation } from 'react-i18next';

function App() {
  const { t, i18n } = useTranslation();

  const BottomRef = useRef(null);

  const scrollBottom = () => {
    BottomRef.current.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <>
    <div id="header">
      <Navbar />

      <div className="content">
        <h1>Waren</h1>
        <h2>Application</h2>
        <p>
          {t('description')}
        </p>
      </div>
      <div className="down" onClick={scrollBottom}>
        <img src={Down} alt="down" />
      </div>
    </div>
    <div id="hero">
      <div className="screens">
        <img src={Screen1} alt="screen" />
        <img src={Screen2} alt="screen" />
        <img src={Screen3} alt="screen" />
      </div>
    </div>
    <div id="download">
      <div className="wrapper">
        <a
          href="https://www.apple.com/fr/store"
          target="_blank"
          rel="noreferrer"
        >
          <img src={AppleStore} alt="appleStore" />
        </a>
        <a
          href="https://play.google.com/store/games?hl=fr&gl=US"
          target="_blank"
          rel="noreferrer"
        >
          <img src={PlayStore} alt="playStore" />
        </a>
      </div>
    </div>
    <div id="about">
      <h2>{t("part1.title")}</h2>
      <p>
        <span>
          {t("part1.blueText")}
        </span>
      </p>
      <p>
        {t("part1.text")}
        <br/><br/>
        {t("part1.subText")}
      </p>
      <h2>{t("part1.subTitle")}</h2>
    </div>
    <section id="time">
      <div className="title">
        <h2>{t("time.title")}</h2>
      </div>
      <div className="content">
        <div className="card">
          <div className="head">
            <h3>{t("time.card1.title")}</h3>
          </div>
          <div className="body">
            <ul>
              <li>
                {t("time.card1.list.1.text")} <span>{t("time.card1.list.1.blueText")}</span>
              </li>
              <li>
                {t("time.card1.list.2.text")} <span>{t("time.card1.list.1.blueText")}</span>
              </li>
              <li>
              {t("time.card1.list.3.text")} <span>{t("time.card1.list.3.blueText")}</span>
              </li>
              <li>
              {t("time.card1.list.4.text")} <span>{t("time.card1.list.4.blueText")}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="card">
          <div className="head">
            <h3>{t("time.card2.title")}</h3>
          </div>
          <div className="body">
            <ul>
              <li>
                {t("time.card2.list.1.text")} <span>{t("time.card2.list.1.blueText")}</span>
              </li>
              <li>
              {t("time.card2.list.2.text")}&nbsp;
                <span>{t("time.card2.list.2.blueText")}</span>
              </li>
              <li>
                {t("time.card2.list.3.text")} <span>{t("time.card2.list.3.blueText")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section id="access-flex">
      <div id="access">
        <div className="title">
          <h2>{t("access.title")}</h2>
        </div>
        <div className="content">
          <div className="card">
            <div className="head">
              <h3>
                {t("access.card.header.title")} <br />
                <span>{t("access.card.header.blueTitle")}</span>
              </h3>
            </div>
            <div className="body">
              <div className="container">
                <p>
                  {t("access.card.body.text")}
                </p>
                <h5>{t("access.card.body.subText")}</h5>
                <div className="price">
                  <div className="trad">
                    <h6>{t("access.card.body.grid.1.title")}</h6>
                    <p>{t("access.card.body.grid.1.text")}</p>
                  </div>
                  <div className="divider"></div>
                  <div className="waren">
                    <h6>{t("access.card.body.grid.2.title")}</h6>
                    <p>{t("access.card.body.grid.2.text")}</p>
                  </div>
                </div>
              </div>
            </div>
            <p className="info">
            {t("access.card.footer.text")}
            </p>
          </div>
        </div>
      </div>
      <div id="flex">
        <div className="title">
          <h2>{t("flex.title")}</h2>
        </div>
        <div className="container">
          <div className="card">
            <img src={Flex1} alt="flex1" />
            <div className="text">
              <h6>{t("flex.grid.1.title")}</h6>
              <p>{t("flex.grid.1.text")}</p>
            </div>
          </div>
          <div className="card">
            <img src={Flex2} alt="flex2" />
            <div className="text">
            <h6>{t("flex.grid.2.title")}</h6>
              <p>{t("flex.grid.2.text")}</p>
            </div>
          </div>
          <div className="card">
            <img src={Flex3} alt="flex3" />
            <div className="text">
            <h6>{t("flex.grid.3.title")}</h6>
              <p>{t("flex.grid.3.text")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="about-secondary">
      <h3>
    {t('part2.title')}
      </h3>
      <ul>
        <li>{t('part2.list.1')}</li>
        <li>{t('part2.list.2')}</li>
        <li>{t('part2.list.3')}</li>
        <li>{t('part2.list.4')}</li>
        <li>{t('part2.list.5')}</li>
      </ul>
      <h3><li>{t('part2.subText')}</li></h3>
    </section>
    <section id="vip">
      <div className="container">
        <div className="title">
          <h2>{t('vip.title')}</h2>
        </div>
        <div className="grid">
          <div className="row">
            <div className="card">
              <p>{t('vip.grid.1')}</p>
            </div>
            <div className="card">
              <p>{t('vip.grid.2')}</p>
            </div>
          </div>
          <div className="row">
            <div className="card">
              <p>{t('vip.grid.3')}</p>
            </div>
            <div className="card">
              <p>{t('vip.grid.4')}</p>
            </div>
          </div>
          <div className="row">
            <div className="card">
              <p>{t('vip.grid.5')}</p>
            </div>
            <div className="card">
              <p>{t('vip.grid.6')}</p>
            </div>
          </div>
        </div>
        <h4>
          {t('vip.text')}
        </h4>
      </div>
    </section>
    <section id="vip-secondary">
      <div className="title">
        <h2>{t('vip2.title')}</h2>
      </div>
      <div className="wrapper">
        <div className="card">
          <p>{t('vip2.grid.1')}</p>
        </div>
        <div className="card">
          <p>{t('vip2.grid.2')}</p>
        </div>
        <div className="card">
          <p>{t('vip2.grid.3')}</p>
        </div>
      </div>
      <h5>
        {t('vip2.text=')}
      </h5>
    </section>
    <section id="faq">
      <div className="title">
        <h2>{t('invest.title')}</h2>
      </div>
      <div className="container">
        <Accordion title={t('invest.grid.1.title')}>
          <p>
          {t('invest.grid.1.text')}
          </p>
          <h5>"{t('invest.grid.1.secondText')}"</h5>
          <h6>{t('invest.grid.1.author')}</h6>
        </Accordion>
        <Accordion title={t('invest.grid.2.title')}>
          <p>
        {t('invest.grid.2.text')}<br/><br/>
        {t('invest.grid.2.secondText')}<br/><br/>
        {t('invest.grid.2.tertiaryText')}
          </p>
          <h5>"{t('invest.grid.2.subText')}"</h5>
          <h6>{t('invest.grid.2.author')}</h6>
        </Accordion>
        <Accordion title="Road map">
          <img src={Roadmap} alt="roadmap" />
        </Accordion>
      </div>
    </section>
    <div className="footer" ref={BottomRef}>
      <img src={Jet} alt="jet" />
      <Link to="/mint">
        <button><p>{t('button')}</p></button>
      </Link>
    </div>
    </>
  );
}

export default App;