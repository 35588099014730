import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          description:
            "WAREN is an on-demand jet booking service through a secure application, on which customers' personal information is not at risk.​",
          part1: {
            title:
              "Why is private aviation attracting more and more customers?",
            blueText:
              "Traveling by jet allows you to bypass the constraints of conventional airports in order to arrive a few minutes before your flight and also to avoid stopovers, to choose your schedule, and all this easily. Traveling by jet also allows, and increasingly, companies to make their executives travel and thus save time and productivity. Travelling by jet will finally allow a whole new segment of the population (startupper, traders and investors) to access this service of aeronautical excellence.​",
            text: "However, we noticed that this high-end service could be further improved. Following the covid-19 crisis, which has hit the whole world, the private jet charter market has enjoyed a surge in popularity. Indeed, the demand for air chartering has changed. In the third quarter of 2019, before the arrival of covid 19; The typical person who rented a private jet is a man with an average age of 40.",
            subText:
              "However, thanks to social networks, demand has exploded despite a very high-end service.",
            subTitle:
              "For more details consult our Market Research or Business Plan*",
          },
          time: {
            title: "TIME SAVING",
            card1: {
              title: "Traditional concierge",
              list: {
                1: {
                  text: "Phone call for quote request :",
                  blueText: "30min",
                },
                2: {
                  text: "Return quote : between ",
                  blueText: "1 heure et 24 heures",
                },
                3: {
                  text: "Support and passing controls :",
                  blueText: "2h30",
                },
                4: {
                  text: "Check-in formalities :",
                  blueText: "1h",
                },
              },
            },
            card2: {
              title: "WAREN",
              list: {
                1: {
                  text: "Application : ",
                  blueText: "Immédiat",
                },
                2: {
                  text: "Support and passing controls :",
                  blueText: "15 minutes",
                },
                3: {
                  text: "Check-in formalities :",
                  blueText: "30 minutes",
                },
              },
            },
          },
          access: {
            title: "ACCESSIBILITY",
            card: {
              header: {
                title: "COMPARATIVE FARE",
                blueTitle: "TRADITIONAL / WAREN",
              },
              body: {
                text: "With the Waren app, prices will be unbeatable, thanks to the empty flight system.​",
                subText: "Fares for a flight PARIS/CANNES :",
                grid: {
                  1: {
                    title: "TRADITIONAL",
                    text: "12 000€",
                  },
                  2: {
                    title: "WAREN",
                    text: "3 000€",
                  },
                },
              },
              footer: {
                text: "This jet, anyway must return to Le Bourget, which is why on the application the return Cannes / Paris will be cheaper. The jet will not stay at Cannes airport, as the storage price will be much higher than just returning to Le Bourget. We have optimized this system to the maximum to allow extremely competitive prices.​",
              },
            },
          },
          flex: {
            title: "FLEXIBILITY",
            grid: {
              1: {
                title: "EXPERTS ADVICE",
                text: "24h/24",
              },
              2: {
                title: "PRICE ESTIMATE​",
                text: "IN REAL TIME​",
              },
              3: {
                title: "BOOKING",
                text: "24h/24",
              },
            },
          },
          part2: {
            title:
              "No need to call or email your concierge to make your booking! Everything happens on the WAREN application:​",
            list: {
              1: "Customization of the flight crew.",
              2: "Choice of device (normally it is imposed).​",
              3: "Choice of options on board. (food, drinks, ...).",
              4: "Simplified boarding and airport time.​",
              5: "Exclusive services offered by our partners.​",
            },
            subText:
              "With Waren you will no longer simply travel by jet, you will create a unique jet trip according to your needs.​",
          },
          vip: {
            title: "VIP SERVICES​",
            grid: {
              1: "Private driver​",
              2: "VIP Private Lounge",
              3: "Gourmet menus to choose from​",
              4: "Flight customization​",
              5: "Confidentiality",
              6: "Unbeatable comfort",
            },
            text: "A private messaging dedicated to all the requests​ of our customers.",
          },
          vip2: {
            title: "VIP SERVICES",
            grid: {
              1: "VERY LIGHT JET / PHENOM 100 & 500",
              2: "LIGHT JET / HAWKER 400",
              3: "MIDDLE JET",
            },
            text: "Each jet is available on ​the Waren app",
          },
          invest: {
            title: "INVESTMENT",
            grid: {
              1: {
                title: "WHY INVEST WITH WAREN?",
                text: "Sabry Pascal Dabou has 7 years of experience in the field of private aviation with his team, he knows the advantages and disadvantages of his sector. This is why he wanted to develop a simple and effective logistics tool for individuals and companies. Today, you have the opportunity to enter this very closed environment of private aviation.",
                subText: "With Waren, Travel in peace and serenity",
                author: "Sabry Pascall Dabou",
              },
              2: {
                title: "OUR VISION & OBJECTIVES",
                text: "Waren allows investors to participate in the construction of the project. The objective is to acquire several aircraft: Embraer Phenom 100, Gulfstream G-400, the Challenger 650. This will increase margins without going through private jet rental providers.",
                secondText:
                  "We also have the ambition to make accessible luxury services, hitherto reserved for a privileged minority of people thanks to a mobile application that is as intuitive as it is simple. We have set ourselves the goal not only to be avant-garde, but also to make good use of available technologies, including blockchain, to make the service flawless and create an easy-to-use application.",
                tertiaryText: "Fundraising target: €50 million",
                subText: "Let's create together, the trajectory of our future",
                author: "Sabry Pascall Dabou",
              },
              3: {
                title: "ROAD MAP",
              },
            },
          },
          button: "PRE-SALE",
        },
      },
      fr: {
        translation: {
          description:
            "WAREN est un service de réservation de jet à la demande grâce à une application sécurisée, sur laquelle les informations personnelles des clients ne courent aucun risque.​",
          part1: {
            title:
              "Pourquoi l'aviation privée attire de plus en plus de clients ?",
            blueText:
              "Voyager en jet permet de passer outre les contraintes des aéroports classiques afin d’arriver quelques minutes avant son vol et aussi d’éviter les escales, de choisir son horaire, et tout cela facilement.Voyager en jet permet aussi, et de plus en plus, aux entreprises de faire voyager leurs cadres et ainsi de gagner du temps et de la productivité.Voyager en jet permettra enfin, à tout une nouvelle tranche de la population (startuper, traders et investisseurs), d’accéder à ce service d’excellence aéronautique.",
            text: "Cependant nous avons remarqué que ce service haut de gamme pouvait être encore amélioré. Suite à la crise du covid-19, qui a frappé le monde entier, le marché de la location de jets privés a bénéficié d’un sursaut de popularité. En effet, la demande en matière d'affrètement aérien a bien changé. Lors du troisième trimestre de 2019, avant l'arrivée du covid 19 ; la personne type qui louait un jet privé est un homme âgé d'en moyenne 40 ans.",
            subText:
              "Cependant grâce aux réseaux sociaux, la demande a explosé malgré un service très haut de gamme.",
            subTitle:
              "Pour plus de détails consultez notre Étude de marché ou Business plan*",
          },
          time: {
            title: "GAIN DE TEMPS",
            card1: {
              title: "CONCIERGERIE TRADITIONNELLE",
              list: {
                1: {
                  text: "Appel téléphonique pour demande de devis :",
                  blueText: "30min",
                },
                2: {
                  text: "Retour devis : entre ",
                  blueText: "1 heure et 24 heures",
                },
                3: {
                  text: "Prise en charge et passage des contrôles :",
                  blueText: "2h30",
                },
                4: {
                  text: "Formalités d’arrivée :",
                  blueText: "1h",
                },
              },
            },
            card2: {
              title: "WAREN",
              list: {
                1: {
                  text: "Application : ",
                  blueText: "Immédiat",
                },
                2: {
                  text: "Prise en charge et passage des contrôles : ",
                  blueText: "15 minutes",
                },
                3: {
                  text: "Formalités d'arrivée : ",
                  blueText: "30 minutes",
                },
              },
            },
          },
          access: {
            title: "ACCESSIBILITÉ",
            card: {
              header: {
                title: "COMPARATIFS DES TARIFS",
                blueTitle: "TRADITIONNEL / WAREN",
              },
              body: {
                text: "Avec l’application Warren, les prix seront imbattables, grâce au système de vols vide.",
                subText: "Les tarifs pour un vol PARIS/CANNES :",
                grid: {
                  1: {
                    title: "TRADITIONNEL",
                    text: "12 000€",
                  },
                  2: {
                    title: "WAREN",
                    text: "3 000€",
                  },
                },
              },
              footer: {
                text: "Ce jet, de toute manière doit retourner au Bourget, c'est pourquoi sur l’application le retour Cannes/Paris sera à moindre coût. Le jet ne restera pas à l'aéroport de Cannes, car le prix de stockage sera beaucoup plus élevé que le simple retour sur le Bourget. Nous avons optimisé ce système au maximum pour permettre des prix extrêmement compétitifs.",
              },
            },
          },
          flex: {
            title: "FLEXIBILITÉ",
            grid: {
              1: {
                title: "CONSEILS D'EXPERTS",
                text: "24h/24",
              },
              2: {
                title: "ESTIMATION DE PRIX",
                text: "EN TEMPS RÉEL",
              },
              3: {
                title: "RÉSERVATION",
                text: "24h/24",
              },
            },
          },
          part2: {
            title:
              "Plus besoin d'appeler, ou d'envoyer d'e-mail à votre conciergerie pour effectuer vos réservations ! Tout ce passe sur l’application WAREN :",
            list: {
              1: "Personnalisation de l'équipage de bord.",
              2: "Choix de son appareil. (en temps normal celui-ci est imposé).",
              3: "Choix des options à bord. (nourriture, boissons, ...).",
              4: "Délai d'embarquement et aéroportuaire simplifié.",
              5: "Des services Exclusifs proposés par nos partenaires.",
            },
            subText:
              "Avec Waren vous ne voyagerez plus simplement en jet, vous créerez un voyage en jet unique selon vos besoin.",
          },
          vip: {
            title: "SERVICES VIP",
            grid: {
              1: "Chauffeur privé",
              2: "Salon privé VIP",
              3: "Menus gastronomiques au choix",
              4: "Personnalisation du vol",
              5: "Confidentialité",
              6: "Confort inégalable",
            },
            text: "Une messagerie privée dédiée à toutes les demandes de nos clients.",
          },
          vip2: {
            title: "SERVICES VIP",
            grid: {
              1: "VERY LIGHT JET / PHENOM 100 & 500",
              2: "LIGHT JET / HAWKER 400",
              3: "MIDDLE JET",
            },
            text: "Chaque jet est disponible sur l'application waren",
          },
          invest: {
            title: "INVESTISSEMENT",
            grid: {
              1: {
                title: "POURQUOI INVESTIR AVEC WAREN ?",
                text: "Sabry Pascal Dabou cumule 7 ans d'expériences dans le domaine de l’aviation privée avec son équipe, il connaît les avantages et les inconvénients de son secteur. C'est pourquoi il a souhaité développer un outil logistique simple et efficace pour les particuliers et les entreprises. Aujourd’hui, vous avez la possibilité de pouvoir pénétrer ce milieu très fermé de l’aviation privée.",
                subText: "Avec Waren, Voyager en toute tranquilité et sérénité",
                author: "Sabry Pascall Dabou",
              },
              2: {
                title: "NOTRE VISION & NOS OBJECTIFS",
                text: "Waren permet aux investisseurs de participer à la construction du projet. L’objectif est d'acquérir plusieurs appareils : Embraer Phenom 100 , Gulfstream G-400, le Challenger 650. Cela permettra d’augmenter les marges sans passer par des fournisseurs de jets privés en location.",
                secondText:
                  "Nous avons également l’ambition de rendre accessible des services de luxe, jusqu’ici réservés à une minorité de personnes privilégiées grâce à une application mobile aussi intuitive que simple. Nous nous sommes donnés l’objectif non seulement d’être avant-gardiste, mais également d’utiliser à bon escient les technologies disponibles, notamment la blockchain, pour rendre le service irréprochable et créer une application facile à utiliser.",
                tertiaryText:
                  "Objectif de la levée de fonds : 50 millions d'euros",
                subText: "Créons ensemble, la trajectoire de notre avenir",
                author: "Sabry Pascall Dabou",
              },
              3: {
                title: "ROAD MAP",
              },
            },
          },
          button: "PRE-SALE",
        },
      },
    },
  });

export default i18n;
